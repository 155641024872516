export default [
  {
    name: 'Kready',
    logo: true,
    pros: [
      'Staff interno alla struttura',
      'Alta tecnologia interna',
      '3 Spazi allestiti',
      'Non è necessario il giorno di allestimento',
      'Impatto energetico sostenibile',
      'No costo facchinaggio e smontaggio',
      'Polifunzionalità dello spazio',
      'Flessibilità'
    ]
  },
  {
    name: 'Altri fornitori',
    pros: [
      'Staff terzi',
      'Tecnologia di terzi esterno',
      '1 solo spazio',
      'Necessario giorno di allestimento',
      'Far arrivare materiale',
      'Pagamento organizzazione esterna',
      'Spazio dedicato',
      'Statico'
    ]
  }
]
