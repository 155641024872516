import React from 'react'
import Container from '../../Container/Container'
import * as style from './location-list.module.scss'
import Section from '../../Section/Section'
import clsx from 'clsx'

type LocationListProps = {
  list: Array<{
    image: string
    title: string
    description: string
  }>
  title?: string
  className?: string
  align: 'center' | 'left'
}

const LocationList = ({
  list,
  title,
  className,
  align = 'center',
  ...props
}: LocationListProps) => {

  const cssName = clsx(style.container, {
    [style[align]]: true,
  }, className)

  const getSrc = (image: string | string[]) => {
    if(image instanceof Array) {
      return image[0]
    }
    return image
  }

  const getSrcSet = (image: string | string[]) => {
    if(image instanceof Array) {
      return image.map((i, key) => `${i} ${key + 1}x`).join(', ')
    }
    return image
  }

  const getDescription = (description: string) => {
    const regex = /<[a-zA-Z\d-/="\'\s]+>/
    const hasHtml = regex.test(description)

    if(hasHtml) {
      return <div dangerouslySetInnerHTML={{ __html: description }}></div>
    }

    return <p>{description}</p>
  }

  return (
    <Section className={cssName} {...props}>
      <Container>
        {title && <h2>{title}</h2>}
        <ul className={style.list}>
          {list.map(s => (
            <li key={s.image}>
              <div className={style.cover}>
                <img src={getSrc(s.image)} srcSet={getSrcSet(s.image)} />
              </div>
              <h4>{s.title}</h4>
              {getDescription(s.description)}
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  )
}

export default LocationList
