export default [
  {
    name: 'Location maggiore piano terra',
    image: '/images/location/map/sala-maggiore-piano-terra.jpg',
    srcset: '/images/location/map/sala-maggiore-piano-terra.jpg 1x'
  },
  {
    name: 'Location maggiore primo piano',
    image: '/images/location/map/sala-maggiore-primo-piano.jpg',
    srcset: '/images/location/map/sala-maggiore-primo-piano.jpg 1x'
  },
  {
    name: 'Officina piano terra',
    image: '/images/location/map/officina-primo-piano.jpg',
    srcset: '/images/location/map/officina-primo-piano.jpg 1x'
  },
  {
    name: 'Officina primo piano',
    image: '/images/location/map/officina-primo-piano.jpg',
    srcset: '/images/location/map/officina-primo-piano.jpg 1x'
  }
]
