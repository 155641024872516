export default ([
  { name: 'Aerogravity', small: "4.6km", image: '/images/location/monuments/aerogravity.jpg' },
  { name: 'Castello Sforzesco', small: "5.6km", image: '/images/location/monuments/castello-sforzesco.jpg' },
  { name: 'Museo del Cenacolo', small: "5.8km", image: '/images/location/monuments/museo-cenacolo.jpg' },
  { name: 'Museo di scienza e della tecnologia', small: "6km", image: '/images/location/monuments/museo-scienza-tecnologia.jpg' },
  { name: 'Museo del \'900', small: "6km", image: '/images/location/monuments/museo-novecento.jpg' },
  { name: 'La Certosa', small: "400m", image: '/images/location/monuments/certosa.jpg' },
  { name: 'Museo del Milan', small: "2.8km", image: '/images/location/monuments/casa-milan.jpg' },
] as any).map((i: any) => {
  i.title = i.name;
  return i
})
