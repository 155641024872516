import ledwall from './ledwall.jpg'
import ledwall2x from './ledwall@2x.jpg'
import regia from './regia.jpg'
import regia2x from './regia@2x.jpg'
import catering2x from './catering@2x.jpg'
import catering from './catering.jpg'

export default [
  {
    title: 'Ledwall',
    image: [ledwall, ledwall2x],
    description: 'Kready offre la possibilità di trasmettere in streaming conferenze, eventi e meeting aziendali.'
  },
  {
    title: 'Regia',
    image: [regia, regia2x],
    description: 'Impianto audio-visivo all’avanguardia adatto ad ogni esigenza. Videocamere, microfoni, ledwall e molto altro a vostra disposizione.'
  },
  {
    title: 'Area catering (non in esclusiva)',
    image: [catering, catering2x],
    description: 'Abbiamo pensato a tutto. Ampia cucina dotata di tutta la strumentazione utile a disposizione del tuo catering preferito.'
  },
]
