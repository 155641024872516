import React, { Component } from 'react'
import Gallery from '../Gallery/Gallery'
import { GalleryProps } from '../Gallery/GalleryProps'

class GalleryHome extends Component<GalleryProps> {
  render () {
    const gSettings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
      ],
      infinite: true,
      autoplay: true,
      dots: true,
      ...this.props.settings,
    }
    return <Gallery settings={gSettings} {...this.props}></Gallery>
  }
}

export default GalleryHome
