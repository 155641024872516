import { StaticImage } from 'gatsby-plugin-image'
import React, { Component } from 'react'
import Container from '../../Container/Container'
import companies from './assets/companies'
import './location-table-compare.scss'

interface LocationTableCompareProps {
  text?: boolean
}
class LocationTableCompare extends Component {
  constructor (props: LocationTableCompareProps) {
    super(props)
  }

  render () {
    return (
      <section id="tabella-comparativa" className='location-table-compare' data-aos='fade'>
        <h2>Perchè scegliere una location Kready</h2>
        <Container>
          <div className='ltc-grid'>
            {companies.map(({ name, logo, pros }) => (
              <div key={name} className='ltc-row'>
                {!logo && <div className='ltc-cell'>{name}</div>}
                {logo && (
                  <div className='ltc-cell'>
                    <StaticImage
                      src='../../../images/kready-logo-color.png'
                      alt='Logo'
                      placeholder='blurred'
                      width={120}
                    />
                  </div>
                )}
                {pros.map(p => (
                  <div key={p} className='ltc-cell'>{p}</div>
                ))}
              </div>
            ))}
          </div>
        </Container>
      </section>
    )
  }
}

export default LocationTableCompare
