import React, { Component } from 'react'
import Container from '../../Container/Container'
import Gallery from '../../Gallery/Gallery'
import lang from './assets/lang'
import images from './assets/images'
import './location-structure-section.scss'
import ButtonPrimary from '../../ButtonPrimary/ButtonPrimary'
import { EVENT } from '../../../const/events'
import { useGaEvent } from '../../../hooks/use-ga-event'

class LocationStructureSection extends Component {
  render () {
    const gallerySettings = {
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      dots: true
    }

    const event = useGaEvent();

    return (
      <section id="struttura" className='location-structure-section' data-aos='fade'>
        <Container className='location-structure-section__content'>
          <div className='lss__content__text'>
            <h2>{lang.title}</h2>
            <p>{lang.paragraph}</p>
            <ul>
              {lang.columns.map(({ title, desc }) => (
                <li key={title} className='lss__content__column'>
                  <p>
                    <b>{title}</b>
                  </p>
                  <p>{desc}</p>
                </li>
              ))}
            </ul>
            <ButtonPrimary
              external
              variant='secondary'
              download
              onClick={() => event(EVENT.DownloadFloorPlans)}
              href='https://drive.google.com/drive/folders/1Z3M3tELDCs1BU2w-JfjbWDuNL_o_ErGF'
            >
              Scarica le planimetrie
            </ButtonPrimary>
          </div>
          <div className='lss__content__gallery'>
            <Gallery
              fullHeight={true}
              settings={gallerySettings}
              images={images}
            ></Gallery>
          </div>
        </Container>
      </section>
    )
  }
}

export default LocationStructureSection
