export default [
  {
    "image": "gadames-location-maggiore-piano-terra",
    "title": "Location maggiore<br>Primo piano",
    "description": "Versatilità al servizio di ogni tipologia di evento."
  },
  {
    "image": "gadames-location-maggiore-primo-piano",
    "title": "Location maggiore<br>Piano terra",
    "description": "L’area più grande e spaziosa di Gadames57."
  },
  {
    "image": "gadames-officina-primo-piano",
    "title": "Officina primo piano",
    "description": "Comparto tecnico ricco di strumentazioni e competenze."
  },
  {
    "image": "gadames-officina-piano-terra",
    "title": "Officina piano terra",
    "description": "Congressi, meeting e spettacoli. Uno spazio versatile e adatto ad ogni esigenza."
  }
];
