import React from 'react'
import assets from './assets'
import './location-hotel.scss'

const LocationHotel = () => {
  return (
    <section id="dove-soggiornare" className='location-hotel'>
      <h2>Dove soggiornare</h2>
      <ul>
        {assets.map((a: any) => (
          <li key={a.image}>
            <img src={a.image} alt={a.name} />
            <p>
              <span>{a.title}</span>
              <span>{a.description}</span>
              <span>{a.small}</span>
            </p>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default LocationHotel
