import React, { Component } from 'react'
import Container from '../../Container/Container'
import spaces from './assets/spaces'
import './location-spaces.scss'
import Section from '../../Section/Section'

function getSrc(image: string) {
  return `/images/location/spaces/${image}.jpg`
}

function getSrcSet(image: string) {
  return `/images/location/spaces/${image}.jpg 1x, /images/location/spaces/${image}@2x.jpg 2x`
}

class LocationSpaces extends Component {
  render () {
    return (
      <Section anchor='spazi' className='location-spaces'>
        <Container>
          <h2>GLI SPAZI</h2>
          <div className='location-spaces__list'>
            {spaces.map(({ image, description, title }) => (
              <div key={title} className='location-spaces__list__element'>
                <div className='location-spaces__list__element__cover'>
                  <img src={getSrc(image)} srcSet={getSrcSet(image)} alt={title} loading="lazy" />
                </div>
                <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                <p>{description}</p>
              </div>
            ))}
          </div>
        </Container>
      </Section>
    )
  }
}

export default LocationSpaces
