export default [
  {
    "icon": "/images/location/icons/blueprint.png",
    "title": "Ampio",
    "description": "1600 metri quadrati divisi in 3 sale versatili ed utilizzabili in base alle esigenze dei nostri clienti"
  },
  {
    "icon": "/images/location/icons/stereo.png",
    "title": "Pronto",
    "description": "Location già arredata e dotata di tecnologia audio-visiva di alta gamma per le migliori performance dell'evento"
  },
  {
    "icon": "/images/location/icons/diamond.png",
    "title": "Versatile",
    "description": "Una location 1000 possibilità di utilizzo. Eventi aziendali, musicali, conferenze stampa, meeting e molto altro."
  },
  {
    "icon": "/images/location/icons/ecology.png",
    "title": "Sostenibile",
    "description": "I primi in Italia a rendere disponibile la possibilità di realizzare eventi ad impatto 0, in un ambiente permanentemente sanificato."
  }
]
