import React from 'react'
import LocationList from '../LocationList/LocationList'
import services from './assets/services'
import './location-services.scss'
import isMobile from '../../../helpers/isMobile'
import Slider from '../../Slider/Slider'
import LocationListElement from '../LocationListElement/LocationListElement'

const LocationServices = () => {
  const isSSR = typeof window !== undefined

  return (
    isMobile() && !isSSR ?
      <Slider
        anchor='servizi'
        className='location-services-slider'
        title='Servizi'>
        {services.map(({ title, description, image }) => (
          <LocationListElement
            key={title}
            title={title}
            image={image}
            align='center'
          >
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </LocationListElement>
        ))}
      </Slider>
    :
      <LocationList
        className='location-services'
        list={services}
        title='Servizi'
      ></LocationList>
  )
}

export default LocationServices
