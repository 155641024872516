import ceneBuffet from './cena-a-buffet.jpg'
import ceneBuffet2x from './cena-a-buffet@2x.jpg'
import ceneGala from './cena-aziendale-di-gala.jpg'
import ceneGala2x from './cena-aziendale-di-gala@2x.jpg'
import diretteStreaming from './diretta-streaming.jpg'
import diretteStreaming2x from './diretta-streaming@2x.jpg'
import eventoSerataDj from './evento-serata-dj.jpg'
import eventoSerataDj2x from './evento-serata-dj@2x.jpg'
import lancioProdotti from './lancio-prodotti.jpg'
import lancioProdotti2x from './lancio-prodotti@2x.jpg'
import meeting from './meeting.jpg'
import meeting2x from './meeting@2x.jpg'

export default [
  {
    "title": "Cene a buffet",
    "image": [ceneBuffet, ceneBuffet2x],
    "description": "<ul><li>300 partecipanti</li><li>Possibilità di party con dj set alla fine della cena</li><li>Ampia selezione di piatti freddi e caldi</li><li>Camerieri per il servizio al buffet</li></ul>"
  },
  {
    "title": "Cene Aziendali di Gala",
    "image": [ceneGala, ceneGala2x],
    "description": "<ul><li>240 partecipanti</li><li>Possibilità di party con dj set alla fine della cena</li><li>Catering di alta qualità</li><li>Camerieri dedicati</li><li>Allestimento elegante</li></ul>"
  },
  {
    "title": "Dirette streaming",
    "image": [diretteStreaming, diretteStreaming2x],
    "description": "<ul><li>Collegati fino a 1000 partecipanti</li><li>150 partecipanti in presenza</li><li>Attrezzatura per lo streaming (telecamere, microfoni, luci, ecc.)</li><li>Piattaforma di streaming professionale</li><li>Wi-Fi ad alta velocità</li></ul>"
  },
  {
    "title": "Evento con serata e DJ",
    "image": [eventoSerataDj, eventoSerataDj2x],
    "description": "<ul><li>Release party</li><li>Esibizione live</li><li>600 partecipanti</li><li>Palco attrezzato per performance live</li><li>Impianti audio e luci all’avanguardia</li></ul>"
  },
  {
    "title": "Lancio di prodotti",
    "image": [lancioProdotti, lancioProdotti2x],
    "description": "<ul><li>300 persone</li><li>Aperitivo con buffet standing</li><li>Area espositiva per il prodotto</li><li>Presentazione del prodotto con schermo e proiettore</li></ul>"
  },
  {
    "title": "Meeting",
    "image": [meeting, meeting2x],
    "description": "<ul><li>230 partecipanti</li><li>Meeting, lunch buffet e sala break</li><li>Wi-Fi ad alta velocità</li><li>Hostess per l'accoglienza</li></ul>"
  }
]
