import React, { Component } from 'react'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import { Helmet } from 'react-helmet'
import ContactForm from '../components/ContactForm/ContactForm'
import Layout from '../components/Layout'
import MediaBox from '../components/MediaBox/MediaBox'
import text from '../lang/it/location.json'
import ButtonPrimary from '../components/ButtonPrimary/ButtonPrimary'
import companyDeckUrl from '../helpers/companyDeckUrl'
import StrenghtsList from '../components/Location/StrengthsList/StrenghtsList'
import LocationSpaces from '../components/Location/LocationSpaces/LocationSpaces'
import LocationTableCompare from '../components/Location/LocationTableCompare/LocationTableCompare'
import LocationStructureSection from '../components/Location/LocationStructureSection/LocationStructureSection'
import LocationWhereWeAre from '../components/Location/LocationWhereWeAre/LocationWhereWeAre'
import LocationMusements from '../components/Location/LocationMusements/LocationMusements'
import LocationHotel from '../components/Location/LocationHotel/LocationHotel'
import LocationEvents30 from '../components/Location/LocationEvents30/LocationEvents30'
import LocationServices from '../components/Location/LocationServices/LocationServices'
import GridLogo from '../components/GridLogo/GridLogo'
import badge from '../components/Location/badge@2x.png';
import virtualTour from '../components/Location/assets/virtual-tour-screen.jpg'
import scrollTo from '../helpers/scrollTo'
import video from '../components/Location/assets/video-banner-gadames.mp4'

import '../scss/pages/location.scss'
import { useGaEvent } from '../hooks/use-ga-event'
import { EVENT } from '../const/events'
import Banner from '../components/Banner/Banner'
import ButtonBanner from '../components/Banner/ButtonBanner'
import Motto from '../components/Motto/Motto'
import LocationBotSection from '../components/Location/LocationBotSection/LocationBotSection'
import LocationHighlights from '../components/Location/LocationHighlights/LocationHighlights'
import LocationGallery from '../components/Location/LocationGallery/LocationGallery'
import LocationMap from '../components/Location/LocationMap/LocationMap'
import isMobile from '../helpers/isMobile'


class Location extends Component {

  constructor (props: any) {
    super(props)
    this.scrollToContactUs.bind(this)
  }

  scrollToContactUs (e: React.MouseEvent) {
      e.preventDefault()
      scrollTo('contact-us')
  }

  render () {
    const event = useGaEvent();
    const mobile = isMobile();

    return (
      <Layout className='location'>
        <Helmet>
          <title>Location | Kready</title>
        </Helmet>
        <Banner>
          <ButtonBanner href="#contact-us">{mobile ? 'Contatti' : 'Entra in contatto'}</ButtonBanner>
          <ButtonBanner href={companyDeckUrl()} download external onClick={() => event('download_presentazione')}
            >{mobile ? 'Presentazione' : 'Scarica la presentazione'}</ButtonBanner>
          {!mobile && <ButtonBanner external
              download
              onClick={() => event(EVENT.DownloadFloorPlans)}
              href='https://drive.google.com/drive/folders/1Z3M3tELDCs1BU2w-JfjbWDuNL_o_ErGF'>Scarica le planimetrie</ButtonBanner>}
        </Banner>
        <MediaBox subhead={<img width="70px" src={badge} />} video={video} title={text.title}>
          <div className='location__buttons'>
            <ButtonPrimary onClick={() => event('download_presentazione')} variant='white' download href={companyDeckUrl()}>
              {text.row.download}
            </ButtonPrimary>
            <ButtonPrimary onClick={this.scrollToContactUs}>Entra in contatto</ButtonPrimary>
          </div>
        </MediaBox>
        <div className='content'>
          <Motto body={text.description}></Motto>
          <LocationWhereWeAre />
          <LocationHighlights />
          <StrenghtsList>
              <ButtonPrimary variant="secondary" onClick={this.scrollToContactUs}>Entra in contatto</ButtonPrimary>
          </StrenghtsList>
          <LocationBotSection />
          <LocationSpaces></LocationSpaces>
          <MediaBox secondary image={virtualTour} title="Scopri ogni angolo di Gadames 57 con il nostro VIRTUAL TOUR">
            <ButtonPrimary
              href="https://my.matterport.com/show/?m=4zPwmzd3uQY"
              external
              onClick={() => event(EVENT.EnterVirtualTour)}>Scopri</ButtonPrimary>
          </MediaBox>
          <LocationStructureSection></LocationStructureSection>
          <LocationEvents30></LocationEvents30>
          <LocationServices></LocationServices>
          <div className="location__button-row location__button-row--btw-sections">
            <ButtonPrimary
              href="/pdf/company-deck-kready.pdf"
              onClick={() => event(EVENT.DownloadDataSheets)}
              variant="secondary" download>Scarica le schede tecniche</ButtonPrimary>
          </div>
        </div>
        <LocationTableCompare></LocationTableCompare>
        <GridLogo primary={true}></GridLogo>
        <LocationGallery />
        <LocationHotel></LocationHotel>
        <LocationMusements></LocationMusements>
        <LocationMap />
        <ContactForm></ContactForm>
      </Layout>
    )
  }
}

export default withBreakpoints(Location as any)
