import React from 'react'
import services from './assets/services'
import './location-events-30.scss'
import Slider from '../../Slider/Slider'
import LocationListElement from '../LocationListElement/LocationListElement'

const LocationEvents30 = () => {
  return (
    <Slider
      className='location-events-30'
      title='Tipologia di eventi'
      align='left'
      anchor='eventi'
    >
      {services.map(({ title, description, image }) => (
        <LocationListElement key={title} title={title} image={image}>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </LocationListElement>
      ))}
    </Slider>
  )
}

export default LocationEvents30
