import React, { Component } from 'react'
import ButtonPrimary from '../../ButtonPrimary/ButtonPrimary'
import ButtonReserveVisit from '../../ButtonReserveVisit/ButtonReserveVisit'
import Container from '../../Container/Container'
import { EVENT } from '../../../const/events'
import { useGaEvent } from '../../../hooks/use-ga-event'

import * as style from './location-where-we-are.module.scss'
import Section from '../../Section/Section'
import Vehicle from './Vehicle'
import { faCar, faSubway, faTram } from '@fortawesome/free-solid-svg-icons'
import map from './map.png'
import map2x from './map@2x.png'

interface LocationWhereWeAreProps {
  text?: boolean
}
class LocationWhereWeAre extends Component {
  constructor(props: LocationWhereWeAreProps) {
    super(props)
  }

  render() {
    const event = useGaEvent()

    return (
      <Section anchor='dove-siamo' className={style.section} data-aos='fade'>
        <Container className={style.container}>
          <div className={style.text}>
            <h2 className={style.title}>Dove siamo</h2>
            <p>
              Gadames 57 si trova nei pressi di viale Certosa, <b>una posizione strategica</b> e
              facilmente raggiungibile da dentro e fuori Milano.<br />
              Un <b>ampio e comodo parcheggio</b> è a disposizione per tutti gli ospiti.
            </p>
            <div className={style.vehicles}>
              <div className={style.vehicleColumn}>
                <Vehicle icon={faSubway}>
                  <b>Metropolitana M1 fermata Uruguay,</b><br />
                  poi 1 Km a piedi o 3 fermate autobus linea 69 (fermata Ghisallo/Gallarate)
                </Vehicle>
                <Vehicle icon={faTram}>
                  <b>Tram linea 14 fermata Certosa/Gradisca</b>
                </Vehicle>
              </div>
              <Vehicle icon={faCar}>
                <b>USCITA MILANO CERTOSA:<br />
                  5 MINUTI</b>
              </Vehicle>
            </div>
            <div className={style.buttons}>
              <ButtonReserveVisit />
              <ButtonPrimary download external onClick={() => event(EVENT.GetDirections)} href='https://www.google.com/maps/place/Gadames+57/@45.4990213,9.1267898,17.04z/data=!4m5!3m4!1s0x4786c1b4f30db413:0xf04a22e14261bed0!8m2!3d45.4990158!4d9.1289188' variant='white'>Ottieni indicazioni</ButtonPrimary>
            </div>
          </div>
          <img src={map} srcSet={`${map} 1x, ${map2x} 2x`} className={style.map} loading="lazy" />
        </Container>
      </Section>
    )
  }
}

export default LocationWhereWeAre
