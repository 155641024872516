import React from 'react'

import * as style from './location-map.module.scss'

import map from './assets/map.jpg'
import map2x from './assets/map@2x.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import Section from '../../Section/Section'
import Container from '../../Container/Container'

const LocationMap = () => {
  return (
    <Section anchor='mappa' className={style.section} style={{ backgroundImage: `image-set(url(${map}) 1x, url(${map2x}) 2x)` }}>
      <Container className={style.container}>
        <div className={style.box} data-aos='fade-in'>
          <div className={style.icon}>
            <FontAwesomeIcon icon={faMapMarker} />
          </div>
          <h2>Gadames57</h2>
          <h3>
            Via Privata Gadames, 57<br />
            20151 Milano MI
          </h3>
          <p>
            Gadames57 è facilmente raggiungibile sia da Milano città, sia da tutti coloro che arrivano da fuori l’hinterland lombardo. Provenendo dalle principali autostrade e proseguendo sulla tangenziale di Milano è semplice raggiungerci passando dall’uscita Milano Certosa.
          </p>
        </div>
      </Container>
    </Section>
  )
}

export default LocationMap
