import React from 'react'
import LocationList from "../LocationList/LocationList"
import services from "./assets/services"

const LocationHighlights = () => {
  return (
    <LocationList
      list={services}
      title='Servizi in evidenza'
      anchor='servizi-in-evidenza'
    ></LocationList>
  )
}

export default LocationHighlights
