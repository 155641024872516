import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'
import { PropsWithChildren } from "react"
import * as style from "./button-banner.module.scss"

type ButtonBannerProps = PropsWithChildren<{
  href: string
  external?: boolean
}> & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

const ButtonBanner = ({ href, children, external, ...props }: ButtonBannerProps) => {
  return <a href={href} target={external ? '_blank' : '_self'} className={style.link} {...props}>{children}</a>
}

export default ButtonBanner
