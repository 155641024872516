'use client'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

import * as style from './masonry-images.module.scss'
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary'

type Image = {
  name: string
  image: string
}

const INCREASER = 6

const MasonryImages = ({ images }: PropsWithChildren<{ images: Image[] }>) => {
  const [count, setCount] = useState(INCREASER)
  const [visibleImages, setVisibleImages] = useState(images.slice(0, count))
  const isSSR = typeof window === "undefined"

  useEffect(() => {
    setVisibleImages(images.slice(0, count))
  }, [images, count])

  const getHeight = () => {
    return [300, 600, 450][Math.floor(Math.random() * 3)];
  }

  const areMoreImages = () => {
    return images.length > count
  }

  return !isSSR ? (
    <div className={style.container}>
      <ResponsiveMasonry className={style.masonry}>
        <Masonry gutter="20px">
          {visibleImages.map(({ name, image }) => (
            <div key={image} style={{ height: getHeight(), backgroundImage: `url(${image})` }} className={style.imageContainer}>
              <p className={style.label}><span>{name}</span></p>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      {areMoreImages() && <ButtonPrimary className={style.button} variant="secondary" onClick={() => setCount(count + INCREASER)}>Mostra di più</ButtonPrimary>}
    </div>
  ) : <></>
}

export default MasonryImages
