export default ([
  {
    name: 'Double Tree by Hilton',
    description: "Via Ludovico di Breme, 77",
    small: "1.5km",
    image: '/images/location/monuments/hilton.jpg'
  },
  {
    name: 'The Hub',
    description: "Via Privata Polonia, 10",
    small: "3.8km",
    image: '/images/location/monuments/piscina.jpg'
  },
  {
    name: 'Voco Milano Fiere',
    description: "Via Giorgio Stephenson, 55",
    small: "3.8km",
    image: '/images/location/monuments/voco-milan.jpg'
  },
  {
    name: 'Klima Hotel Milano',
    description: "Via Privata Venezia Giulia, 8",
    small: "3.9km",
    image: '/images/location/hotel/klima-hotel-milano.jpg'
  },
] as any).map((i: any) => {
  i.title = i.name;
  return i
})
