import gestioneRegiaTecnica from './gestione-regia-tecnica.jpg'
import gestioneRegiaTecnica2x from './gestione-regia-tecnica@2x.jpg'

export default [
  {
    title: 'Diretta streaming on demand',
    image: '/images/location/services/diretta-streaming.jpg',
    description: 'Kready offre la possibilità di trasmettere in streaming conferenze, eventi e meeting aziendali.'
  },
  {
    title: 'Service high-tech',
    image: '/images/location/services/audio-visivo-high-tech.jpg',
    description: 'Impianto audio-visivo all’avanguardia adatto ad ogni esigenza. Videocamere, microfoni, ledwall e molto altro a vostra disposizione.'
  },
  {
    title: 'SANIFICAZIONE COSTANTE',
    image: '/images/location/services/sanificazione-costante.jpg',
    description: 'I primi in Italia a rendere disponibile la possibilità di realizzare eventi ad impatto 0, in un ambiente permanentemente sanificato.'
  },
  {
    title: 'Area catering',
    image: '/images/location/services/catering.jpg',
    description: 'Ampia cucina, dotata di tutta la strumentazione utile per ospitare il servizio catering che avete selezionato.'
  },
  {
    title: 'GESTIONE REGIA TECNICA',
    image: [gestioneRegiaTecnica, gestioneRegiaTecnica2x],
    description: 'Kready affianca il cliente nello sviluppo di una regia tecnica dedicata adatta ad ogni tipo di evento.'
  },
  {
    title: 'ALLESTIMENTO DEDICATO',
    image: '/images/location/services/allestimento-dedicato.jpg',
    description: 'La struttura presenta una forte modularità e flessibilità degli spazi interni ed esterni, per soddisfare esigenze organizzative e gestionali.'
  },
]
