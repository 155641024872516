import React, { PropsWithChildren } from 'react'
import * as style from './location-list-element.module.scss'
import clsx from 'clsx'

const LocationListElement = ({ title, image, children, align = 'left' }: PropsWithChildren<{ title: string, image: string | string[], align: 'left' | 'center' }>) => {

  const getSrc = (image: string | string[]) => {
    if (image instanceof Array) {
      return image[0]
    }
    return image
  }

  const getSrcSet = (image: string | string[]) => {
    if (image instanceof Array) {
      return image.map((i, key) => `${i} ${key + 1}x`).join(', ')
    }
    return image
  }

  return (
    <div className={clsx(style.element, style[align])}>
      <div className={style.cover}>
        <img src={getSrc(image)} srcSet={getSrcSet(image)} alt={title} />
      </div>
      <h4 className={style.title}>{title}</h4>
      {children}
    </div>
  )
}

export default LocationListElement
