export default {
    "title": "Overview struttura",
    "paragraph": "Le aree comuni di servizio al piano terra occupano 250 mq e comprendono due stanze adibite ad area catering (uno ospita le celle frigorifere), un’area stoccaggio e gli spogliatoi, inoltre dispongono di un ingresso merci dedicato e di ingresso pedonale per il personale. Nei piani interati sono disponibili altre aree di stoccaggio, un caveau, altri spogliatoi e un locale docce sempre per il personale.",
    "columns": [
        {
            "title": "4 spazi",
            "desc": "2 location maggiori e 2 locali minori"
        },
        {
            "title": "600mq",
            "desc": "Terrazzo panoramico"
        },
        {
            "title": "2 esterni",
            "desc": "Un parterre e un’ulteriore area esterna"
        }
    ]
}
