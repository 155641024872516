import React from 'react'
import GalleryHome from '../../GalleryHome/GalleryHome'
import assets from './assets'
import './location-musements.scss'

const settings = {
  arrows: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }
  ]
}

const LocationMusements = () => {
  return (
    <section id="attivita-attrazioni" className='location-gallery'>
      <h2>Attività e attrazioni</h2>
      <GalleryHome images={assets} settings={ settings }></GalleryHome>
    </section>
  )
}

export default LocationMusements
