import React from "react"
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary"
import MasonryImages from "../../MasonryImages/MasonryImages"
import getLocationGalleryImages from "../../../helpers/getLocationGalleryImages"
import Section from "../../Section/Section"

import * as style from './location-gallery.module.scss'
import GalleryHome from "../../GalleryHome/GalleryHome"
import isMobile from "../../../helpers/isMobile"

const LocationGallery = () => {
  const images = getLocationGalleryImages()

  return (
    <Section anchor="gallery" className={style.section}>
      <div className={style.header}>
        <h2 className={style.title}>Gallery</h2>
        <div>
          <ButtonPrimary variant="secondary" download href="/images/gallery/kready-location-gallery.zip">
            Scarica le foto della Location
          </ButtonPrimary>
        </div>
      </div>
      {isMobile() ? <GalleryHome images={images}></GalleryHome> : <MasonryImages images={images}></MasonryImages>}
    </Section>
  )
}

export default LocationGallery
