import React, { PropsWithChildren } from 'react'
import list from './assets/list'
import ListTextIcons from '../../ListTextIcons/list-text-icons'

const StrenghtsList = ({ children }: PropsWithChildren<{}>) => {
    return (
      <ListTextIcons id="punti-forza" elements={list}>
        {children}
      </ListTextIcons>
    )
}

export default StrenghtsList
