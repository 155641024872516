'use client'
import React, { RefObject, useEffect } from 'react'
import { LuminousGallery } from 'luminous-lightbox'
import Container from '../Container/Container'
import { Helmet } from 'react-helmet'
import * as style from './slider.module.scss'
import './global.scss'
import SlickSlider from 'react-slick'
import { SliderProps } from './SliderProps'
import clsx from 'clsx'
import isMobile from '../../helpers/isMobile'

const Slider = ({ title, fullHeight, className = '', anchor = '', children }: SliderProps) => {
  let slider: any = ''

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: !isMobile(),
    dots: true,
    infinite: true,
    autoplay: true,
    adaptiveHeight: isMobile(),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
    ],
  }

  const galleryRef: RefObject<HTMLDivElement> = React.createRef()

  useEffect(() => {
    new LuminousGallery(galleryRef.current?.querySelectorAll('.luminous'))
  }, [])

  const classes = clsx(style.gallery, {
    [style.galleryFullHeight]: fullHeight,
  }, className)

  return (
    <div
      data-aos='fade-in'
      className={classes}
      ref={galleryRef}
    >
      <Helmet>
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          rel='stylesheet'
          type='text/css'
        />
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          rel='stylesheet'
          type='text/css'
        />
      </Helmet>
      <Container id={anchor} className={style.container}>
        <h2>{title}</h2>
        <div className={style.content}>
          <SlickSlider ref={c => (slider = c)} {...settings}>
            {children}
          </SlickSlider>
        </div>
      </Container>
    </div>
  )
}

export default Slider
