import React from 'react'
import { PropsWithChildren } from "react"
import * as style from "./banner.module.scss"

const Banner = ({ children }: PropsWithChildren<{}>) => {
  return <div className={style.banner}>
    {children}
  </div>
}

export default Banner
