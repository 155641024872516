import React, { PropsWithChildren } from 'react'
import * as style from './vehicle.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IconDefinition } from '@fortawesome/free-brands-svg-icons'

type VehicleProps = PropsWithChildren<{
  icon?: IconProp | IconDefinition
}>

const Vehicle = ({ icon, children }: VehicleProps) => {
  return (
    <div className={style.vehicle}>
      {icon &&
        <div className={style.icon}>
          <FontAwesomeIcon icon={icon as IconProp} />
        </div>}
      <div style={style.description}>
        <p>{children}</p>
      </div>
    </div>
  )
}

export default Vehicle
